import React from 'react';
import { Link } from 'react-router-dom';


function About() {
  return (
    <div>About

<Link to="/">Home</Link>
<h1 className='text-7xl text-center text-blue-400'>About</h1>
    </div>
  )
}

export default About