
import Home from '../Pages/Home';
import {BrowserRouter , Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import About from '../Pages/About';
import ProductList from '../Pages/ProductList';


export default function RouterPage() {
    return (
      <div>
          <BrowserRouter>
            <ScrollToTop/>
          {/* <ScrollTO/> */}
              <Routes>
              <Route path='/' exact element={<Home/>}></Route>
              {/* <Route path='/' exact element={*}></Route> */}
             <Route path='/about' element={<About/>}></Route>
             <Route path='/product-list' element={<ProductList/>}></Route>
              {/*  <Route path='/peb-structures' element={<PEBStructures/>}></Route>
              <Route path='/real-estate' element={<RealEstate/>}></Route>
              <Route path='/sustainable-projects' element={<SustainableServices/>}></Route>
              <Route path='/bim-services' element={<BIMServices/>}></Route>
              <Route path='/structural-audit' element={<StructuralAudit/>}></Route>

              <Route path='/products' element={<Products/>}></Route>
              <Route path='/contact' element={<Contact/>}></Route>

              <Route path='/about' element={<GetToKnow/>}></Route>
              <Route path='/principles' element={<Principles/>}></Route>
              <Route path='/our-team' element={<OurTeam/>}></Route>
              <Route path='/achievements' element={<Achievements/>}></Route>
              <Route path='/certifications' element={<Certifications/>}></Route>
              <Route path='/featured-news' element={<FeaturedNews/>}></Route>
              <Route path='/software-associates' element={<SoftwareAssociates/>}></Route>
              <Route path='/product-details' element={<ProductDetails/>}></Route>
              <Route path='/projects' element={<Projects/>}></Route>
              <Route path='/careers' element={<Careers/>}></Route>
              <Route path='/enquiry' element={<Enquiry/>}></Route>
              <Route path='/blogs' element={<Blog/>}></Route>
              <Route path='/cart' element={<Cart/>}></Route>

              <Route path='/signup' element={<Signup/>}></Route>
              <Route path='/login' element={<Login/>}></Route> */}
                  </Routes>
          </BrowserRouter>
      </div>
    )
  }