import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import ProductBanner from '../Components/ProductBanner'
import { Link } from 'react-router-dom'
import BreakImg from './../Images/break.webp';
import { Button } from 'react-bootstrap';

const ProductList = () => {
    return (
        <>
            <Header />
            <ProductBanner />
            <section className="sec-80">
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="category-list ">
                                <div class="p-3 w-full h-auto">
                                    <div class="flex justify-between pr-2">
                                        <div class="text-lg font-semibold">19 Results</div>
                                    </div>
                                    <div class="text-sm text-blue-secondary font-semibold flex cursor-pointer pb-3">Clear filters <span
                                        class="pl-1"><svg class="svg-inline--fa fa-rotate-right" aria-hidden="true" focusable="false"
                                            data-prefix="fas" data-icon="rotate-right" role="img" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512">
                                            <path class="" fill="currentColor"
                                                d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z">
                                            </path>
                                        </svg></span></div>
                                    <div>
                                        <div>
                                            <div>
                                                <div data-v-241e2ed0="" class="py-2 border-t border-gray-200 text-sm"><button data-v-241e2ed0=""
                                                    id="headlessui-disclosure-button-3" type="button" aria-expanded="true"
                                                    aria-controls="headlessui-disclosure-panel-4" data-headlessui-state="open"
                                                    class="py-2 font-semibold flex justify-between w-full" fdprocessedid="qudxob"><span
                                                        data-v-241e2ed0="">Brand</span><span data-v-241e2ed0=""><svg
                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-down" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                            </path>
                                                        </svg></span><span data-v-241e2ed0="" className="hidden"><svg
                                                            class="svg-inline--fa fa-chevron-up" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-up" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z">
                                                            </path>
                                                        </svg></span></button>
                                                    <div data-v-241e2ed0="" id="headlessui-disclosure-panel-4" data-headlessui-state="open"
                                                        class="pb-2">
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="volvo" class="cursor-pointer" value="volvo" /><label
                                                                    data-v-241e2ed0="" for="volvo" class="px-4 lg:px-2 cursor-pointer">Volvo</label>
                                                            </div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(55)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="daf" class="cursor-pointer" value="daf" /><label
                                                                    data-v-241e2ed0="" for="daf" class="px-4 lg:px-2 cursor-pointer">DAF</label>
                                                            </div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(24)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="mercedes" class="cursor-pointer" value="mercedes" /><label
                                                                    data-v-241e2ed0="" for="mercedes"
                                                                    class="px-4 lg:px-2 cursor-pointer">Mercedes</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(20)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="renault" class="cursor-pointer" value="renault" /><label
                                                                    data-v-241e2ed0="" for="renault"
                                                                    class="px-4 lg:px-2 cursor-pointer">Renault</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(19)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="man" class="cursor-pointer" value="man" /><label
                                                                    data-v-241e2ed0="" for="man" class="px-4 lg:px-2 cursor-pointer">MAN</label>
                                                            </div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(3)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="text-blue-secondary flex items-center w-full shrink-0 inline-block font-bold py-2"><span data-v-241e2ed0=""><svg class=" w-[20px] svg-inline--fa  fa-plus fa-sm" aria-hidden="true"
                                                            focusable="false" data-prefix="fas" data-icon="plus" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                            <path class="" fill="currentColor"
                                                                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z">
                                                            </path>
                                                        </svg></span><span data-v-241e2ed0="" class="mx-1 shrink-0 inline-block w-full cursor-pointer">Show more</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div data-v-241e2ed0="" class="py-2 border-t border-gray-200 text-sm"><button data-v-241e2ed0=""
                                                    id="headlessui-disclosure-button-5" type="button" aria-expanded="true"
                                                    aria-controls="headlessui-disclosure-panel-6" data-headlessui-state="open"
                                                    class="py-2 font-semibold flex justify-between w-full" fdprocessedid="iadg7"><span
                                                        data-v-241e2ed0="">New / used</span><span data-v-241e2ed0=""><svg
                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-down" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                            </path>
                                                        </svg></span><span data-v-241e2ed0="" className="hidden"><svg
                                                            class="svg-inline--fa fa-chevron-up" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-up" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z">
                                                            </path>
                                                        </svg></span></button>
                                                    <div data-v-241e2ed0="" id="headlessui-disclosure-panel-6" data-headlessui-state="open"
                                                        class="pb-2">
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="new" class="cursor-pointer" value="new" /><label
                                                                    data-v-241e2ed0="" for="new" class="px-4 lg:px-2 cursor-pointer">New</label>
                                                            </div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(2)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="used" class="cursor-pointer" value="used" /><label
                                                                    data-v-241e2ed0="" for="used" class="px-4 lg:px-2 cursor-pointer">Used</label>
                                                            </div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(17)</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div class="py-2 border-t border-gray-200"><button id="headlessui-disclosure-button-7" type="button"
                                                    aria-expanded="true" aria-controls="headlessui-disclosure-panel-8"
                                                    data-headlessui-state="open" class="text-sm py-2 flex justify-between w-full"
                                                    fdprocessedid="a35bdh">
                                                    <div class="font-semibold">Sales price <span class="text-xs text-gray-400 ml-2"></span>
                                                    </div><span><svg class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                        data-prefix="fas" data-icon="chevron-down" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path class="" fill="currentColor"
                                                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                        </path>
                                                    </svg></span><span className="hidden"><svg class="svg-inline--fa fa-chevron-up"
                                                        aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path class="" fill="currentColor"
                                                            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z">
                                                        </path>
                                                    </svg></span>
                                                </button>
                                                    <div id="headlessui-disclosure-panel-8" data-headlessui-state="open">
                                                        <div class="flex justify-between gap-4 pb-2">
                                                            <div class="w-1/2">
                                                                <div class="relative mt-1 w-full" data-headlessui-state=""><button
                                                                    id="headlessui-listbox-button-9" type="button" aria-haspopup="listbox"
                                                                    aria-expanded="false" data-headlessui-state=""
                                                                    class="relative w-full border border-gray-300 cursor-pointor bg-white lg:py-1 py-2 px-2 pr-10 text-left text-sm lg:text-xs"
                                                                    fdprocessedid="oown6c"><span class="block truncate">From</span><span
                                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><span><svg
                                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true"
                                                                            focusable="false" data-prefix="fas" data-icon="chevron-down"
                                                                            role="img" xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512">
                                                                            <path class="" fill="currentColor"
                                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                            </div>
                                                            <div class="w-1/2">
                                                                <div class="relative mt-1 w-full" data-headlessui-state=""><button
                                                                    id="headlessui-listbox-button-11" type="button" aria-haspopup="listbox"
                                                                    aria-expanded="false" data-headlessui-state=""
                                                                    class="relative w-full border border-gray-300 cursor-pointor bg-white lg:py-1 py-2 px-2 pr-10 text-left text-sm lg:text-xs"
                                                                    fdprocessedid="1u3vjf"><span class="block truncate">To</span><span
                                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><span><svg
                                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true"
                                                                            focusable="false" data-prefix="fas" data-icon="chevron-down"
                                                                            role="img" xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512">
                                                                            <path class="" fill="currentColor"
                                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div data-v-241e2ed0="" class="py-2 border-t border-gray-200 text-sm"><button data-v-241e2ed0=""
                                                    id="headlessui-disclosure-button-13" type="button" aria-expanded="true"
                                                    aria-controls="headlessui-disclosure-panel-14" data-headlessui-state="open"
                                                    class="py-2 font-semibold flex justify-between w-full" fdprocessedid="t9ekn9q"><span
                                                        data-v-241e2ed0="">Engine type</span><span data-v-241e2ed0=""><svg
                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-down" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                            </path>
                                                        </svg></span><span data-v-241e2ed0="" className="hidden"><svg
                                                            class="svg-inline--fa fa-chevron-up" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-up" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z">
                                                            </path>
                                                        </svg></span></button>
                                                    <div data-v-241e2ed0="" id="headlessui-disclosure-panel-14" data-headlessui-state="open"
                                                        class="pb-2">
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="DTI11 380" class="cursor-pointer" value="DTI11 380" /><label
                                                                    data-v-241e2ed0="" for="DTI11 380" class="px-4 lg:px-2 cursor-pointer">DTI11
                                                                    380</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(2)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="DTI11 430" class="cursor-pointer" value="DTI11 430" /><label
                                                                    data-v-241e2ed0="" for="DTI11 430" class="px-4 lg:px-2 cursor-pointer">DTI11
                                                                    430</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(2)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="DTI11 460" class="cursor-pointer" value="DTI11 460" /><label
                                                                    data-v-241e2ed0="" for="DTI11 460" class="px-4 lg:px-2 cursor-pointer">DTI11
                                                                    460</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(2)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="DTI13 480" class="cursor-pointer" value="DTI13 480" /><label
                                                                    data-v-241e2ed0="" for="DTI13 480" class="px-4 lg:px-2 cursor-pointer">DTI13
                                                                    480</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(1)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="DTI5 210" class="cursor-pointer" value="DTI5 210" /><label
                                                                    data-v-241e2ed0="" for="DTI5 210" class="px-4 lg:px-2 cursor-pointer">DTI5
                                                                    210</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(1)</div>
                                                        </div>
                                                        <div className=''>
                                                            <div data-v-241e2ed0="" class="text-blue-secondary flex items-center w-full shrink-0 inline-block font-bold py-2"><span data-v-241e2ed0=""><svg class=" w-[20px] svg-inline--fa  fa-plus fa-sm" aria-hidden="true"
                                                                focusable="false" data-prefix="fas" data-icon="plus" role="img"
                                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                <path class="" fill="currentColor"
                                                                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z">
                                                                </path>
                                                            </svg></span><span data-v-241e2ed0="" class="mx-1 shrink-0 inline-block w-full cursor-pointer">Show more</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div data-v-241e2ed0="" class="py-2 border-t border-gray-200 text-sm"><button data-v-241e2ed0=""
                                                    id="headlessui-disclosure-button-15" type="button" aria-expanded="true"
                                                    aria-controls="headlessui-disclosure-panel-16" data-headlessui-state="open"
                                                    class="py-2 font-semibold flex justify-between w-full" fdprocessedid="djvlbw"><span
                                                        data-v-241e2ed0="">Motor brake type</span><span data-v-241e2ed0=""><svg
                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-down" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                            </path>
                                                        </svg></span><span data-v-241e2ed0="" className="hidden"><svg
                                                            class="svg-inline--fa fa-chevron-up" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-up" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z">
                                                            </path>
                                                        </svg></span></button>
                                                    <div data-v-241e2ed0="" id="headlessui-disclosure-panel-16" data-headlessui-state="open"
                                                        class="pb-2">
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="EPG" class="cursor-pointer" value="EPG" /><label
                                                                    data-v-241e2ed0="" for="EPG" class="px-4 lg:px-2 cursor-pointer">EPG</label>
                                                            </div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(7)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="Optibrake" class="cursor-pointer" value="Optibrake" /><label
                                                                    data-v-241e2ed0="" for="Optibrake"
                                                                    class="px-4 lg:px-2 cursor-pointer">Optibrake</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(8)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="Optibrake+" class="cursor-pointer" value="Optibrake+" /><label
                                                                    data-v-241e2ed0="" for="Optibrake+"
                                                                    class="px-4 lg:px-2 cursor-pointer">Optibrake+</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(1)</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div class="py-2 border-t border-gray-200"><button id="headlessui-disclosure-button-17"
                                                    type="button" aria-expanded="true" aria-controls="headlessui-disclosure-panel-18"
                                                    data-headlessui-state="open" class="text-sm py-2 flex justify-between w-full"
                                                    fdprocessedid="qujk0d">
                                                    <div class="font-semibold">Mileage (km) <span class="text-xs text-gray-400 ml-2"></span>
                                                    </div><span><svg class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                        data-prefix="fas" data-icon="chevron-down" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path class="" fill="currentColor"
                                                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                        </path>
                                                    </svg></span><span className="hidden"><svg class="svg-inline--fa fa-chevron-up"
                                                        aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path class="" fill="currentColor"
                                                            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z">
                                                        </path>
                                                    </svg></span>
                                                </button>
                                                    <div id="headlessui-disclosure-panel-18" data-headlessui-state="open">
                                                        <div class="flex justify-between gap-4 pb-2">
                                                            <div class="w-1/2">
                                                                <div class="relative mt-1 w-full" data-headlessui-state=""><button
                                                                    id="headlessui-listbox-button-19" type="button" aria-haspopup="listbox"
                                                                    aria-expanded="false" data-headlessui-state=""
                                                                    class="relative w-full border border-gray-300 cursor-pointor bg-white lg:py-1 py-2 px-2 pr-10 text-left text-sm lg:text-xs"
                                                                    fdprocessedid="ymljcd"><span class="block truncate">From</span><span
                                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><span><svg
                                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true"
                                                                            focusable="false" data-prefix="fas" data-icon="chevron-down"
                                                                            role="img" xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512">
                                                                            <path class="" fill="currentColor"
                                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                            </div>
                                                            <div class="w-1/2">
                                                                <div class="relative mt-1 w-full" data-headlessui-state=""><button
                                                                    id="headlessui-listbox-button-21" type="button" aria-haspopup="listbox"
                                                                    aria-expanded="false" data-headlessui-state=""
                                                                    class="relative w-full border border-gray-300 cursor-pointor bg-white lg:py-1 py-2 px-2 pr-10 text-left text-sm lg:text-xs"
                                                                    fdprocessedid="sjid66g"><span class="block truncate">To</span><span
                                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><span><svg
                                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true"
                                                                            focusable="false" data-prefix="fas" data-icon="chevron-down"
                                                                            role="img" xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512">
                                                                            <path class="" fill="currentColor"
                                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div class="py-2 border-t border-gray-200"><button id="headlessui-disclosure-button-23"
                                                    type="button" aria-expanded="true" aria-controls="headlessui-disclosure-panel-24"
                                                    data-headlessui-state="open" class="text-sm py-2 flex justify-between w-full"
                                                    fdprocessedid="bnbz">
                                                    <div class="font-semibold">Production year <span class="text-xs text-gray-400 ml-2"></span>
                                                    </div><span><svg class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                        data-prefix="fas" data-icon="chevron-down" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path class="" fill="currentColor"
                                                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                        </path>
                                                    </svg></span><span className="hidden"><svg class="svg-inline--fa fa-chevron-up"
                                                        aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path class="" fill="currentColor"
                                                            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z">
                                                        </path>
                                                    </svg></span>
                                                </button>
                                                    <div id="headlessui-disclosure-panel-24" data-headlessui-state="open">
                                                        <div class="flex justify-between gap-4 pb-2">
                                                            <div class="w-1/2">
                                                                <div class="relative mt-1 w-full" data-headlessui-state=""><button
                                                                    id="headlessui-listbox-button-25" type="button" aria-haspopup="listbox"
                                                                    aria-expanded="false" data-headlessui-state=""
                                                                    class="relative w-full border border-gray-300 cursor-pointor bg-white lg:py-1 py-2 px-2 pr-10 text-left text-sm lg:text-xs"
                                                                    fdprocessedid="ui6h4t"><span class="block truncate">From</span><span
                                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><span><svg
                                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true"
                                                                            focusable="false" data-prefix="fas" data-icon="chevron-down"
                                                                            role="img" xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512">
                                                                            <path class="" fill="currentColor"
                                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                            </div>
                                                            <div class="w-1/2">
                                                                <div class="relative mt-1 w-full" data-headlessui-state=""><button
                                                                    id="headlessui-listbox-button-27" type="button" aria-haspopup="listbox"
                                                                    aria-expanded="false" data-headlessui-state=""
                                                                    class="relative w-full border border-gray-300 cursor-pointor bg-white lg:py-1 py-2 px-2 pr-10 text-left text-sm lg:text-xs"
                                                                    fdprocessedid="0haobj"><span class="block truncate">To</span><span
                                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><span><svg
                                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true"
                                                                            focusable="false" data-prefix="fas" data-icon="chevron-down"
                                                                            role="img" xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512">
                                                                            <path class="" fill="currentColor"
                                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div data-v-241e2ed0="" class="py-2 border-t border-gray-200 text-sm"><button data-v-241e2ed0=""
                                                    id="headlessui-disclosure-button-29" type="button" aria-expanded="true"
                                                    aria-controls="headlessui-disclosure-panel-30" data-headlessui-state="open"
                                                    class="py-2 font-semibold flex justify-between w-full" fdprocessedid="bxr9uq"><span
                                                        data-v-241e2ed0="">Model</span><span data-v-241e2ed0=""><svg
                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-down" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                            </path>
                                                        </svg></span><span data-v-241e2ed0="" className="hidden"><svg
                                                            class="svg-inline--fa fa-chevron-up" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-up" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z">
                                                            </path>
                                                        </svg></span></button>
                                                    <div data-v-241e2ed0="" id="headlessui-disclosure-panel-30" data-headlessui-state="open"
                                                        class="pb-2">
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="D-Serie" class="cursor-pointer" value="D-Serie" /><label
                                                                    data-v-241e2ed0="" for="D-Serie"
                                                                    class="px-4 lg:px-2 cursor-pointer">D-Serie</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(14)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="T-Serie" class="cursor-pointer" value="T-Serie" /><label
                                                                    data-v-241e2ed0="" for="T-Serie"
                                                                    class="px-4 lg:px-2 cursor-pointer">T-Serie</label></div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(5)</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div class="py-2 border-t border-gray-200"><button id="headlessui-disclosure-button-31"
                                                    type="button" aria-expanded="true" aria-controls="headlessui-disclosure-panel-32"
                                                    data-headlessui-state="open" class="text-sm py-2 flex justify-between w-full"
                                                    fdprocessedid="wa58en">
                                                    <div class="font-semibold">Power (hp) <span class="text-xs text-gray-400 ml-2"></span></div>
                                                    <span><svg class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                        data-prefix="fas" data-icon="chevron-down" role="img"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path class="" fill="currentColor"
                                                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                        </path>
                                                    </svg></span><span className="hidden"><svg class="svg-inline--fa fa-chevron-up"
                                                        aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path class="" fill="currentColor"
                                                            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z">
                                                        </path>
                                                    </svg></span>
                                                </button>
                                                    <div id="headlessui-disclosure-panel-32" data-headlessui-state="open">
                                                        <div class="flex justify-between gap-4 pb-2">
                                                            <div class="w-1/2">
                                                                <div class="relative mt-1 w-full" data-headlessui-state=""><button
                                                                    id="headlessui-listbox-button-33" type="button" aria-haspopup="listbox"
                                                                    aria-expanded="false" data-headlessui-state=""
                                                                    class="relative w-full border border-gray-300 cursor-pointor bg-white lg:py-1 py-2 px-2 pr-10 text-left text-sm lg:text-xs"
                                                                    fdprocessedid="m1bcqh"><span class="block truncate">From</span><span
                                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><span><svg
                                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true"
                                                                            focusable="false" data-prefix="fas" data-icon="chevron-down"
                                                                            role="img" xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512">
                                                                            <path class="" fill="currentColor"
                                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                            </div>
                                                            <div class="w-1/2">
                                                                <div class="relative mt-1 w-full" data-headlessui-state=""><button
                                                                    id="headlessui-listbox-button-35" type="button" aria-haspopup="listbox"
                                                                    aria-expanded="false" data-headlessui-state=""
                                                                    class="relative w-full border border-gray-300 cursor-pointor bg-white lg:py-1 py-2 px-2 pr-10 text-left text-sm lg:text-xs"
                                                                    fdprocessedid="rxvepu"><span class="block truncate">To</span><span
                                                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><span><svg
                                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true"
                                                                            focusable="false" data-prefix="fas" data-icon="chevron-down"
                                                                            role="img" xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 512 512">
                                                                            <path class="" fill="currentColor"
                                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div data-v-241e2ed0="" class="py-2 border-t border-gray-200 text-sm"><button data-v-241e2ed0=""
                                                    id="headlessui-disclosure-button-37" type="button" aria-expanded="true"
                                                    aria-controls="headlessui-disclosure-panel-38" data-headlessui-state="open"
                                                    class="py-2 font-semibold flex justify-between w-full" fdprocessedid="5s0a1"><span
                                                        data-v-241e2ed0="">Common rail</span><span data-v-241e2ed0=""><svg
                                                            class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-down" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                            </path>
                                                        </svg></span><span data-v-241e2ed0="" className="hidden"><svg
                                                            class="svg-inline--fa fa-chevron-up" aria-hidden="true" focusable="false"
                                                            data-prefix="fas" data-icon="chevron-up" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path class="" fill="currentColor"
                                                                d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z">
                                                            </path>
                                                        </svg></span></button>
                                                    <div data-v-241e2ed0="" id="headlessui-disclosure-panel-38" data-headlessui-state="open"
                                                        class="pb-2">
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="Ja" class="cursor-pointer" value="Ja" /><label
                                                                    data-v-241e2ed0="" for="Ja" class="px-4 lg:px-2 cursor-pointer">Yes</label>
                                                            </div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(14)</div>
                                                        </div>
                                                        <div data-v-241e2ed0="" class="flex justify-between py-1 items-center">
                                                            <div data-v-241e2ed0="" class="flex items-center"><input data-v-241e2ed0=""
                                                                type="checkbox" id="Nee" class="cursor-pointer" value="Nee" /><label
                                                                    data-v-241e2ed0="" for="Nee" class="px-4 lg:px-2 cursor-pointer">No</label>
                                                            </div>
                                                            <div data-v-241e2ed0="" class="text-gray-400">(3)</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">

                            <div class="popular-products ">
                                <div className='flex justify-end'>
                                <div class="p-2 w-1/3 mb-4 shrink-0">
                                    <div class="relative mt-1 w-auto" data-headlessui-state=""><button id="headlessui-listbox-button-39"
                                        type="button" aria-haspopup="listbox" aria-expanded="false" data-headlessui-state=""
                                        class="relative w-full rounded border border-blue-dark cursor-pointer bg-white py-2 px-2 pr-10 text-left text-sm"
                                        fdprocessedid="h4tmj"><span class="block truncate">Sort by relevance</span><span
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><span><svg
                                                class="svg-inline--fa fa-chevron-down" aria-hidden="true" focusable="false"
                                                data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512">
                                                <path class="" fill="currentColor"
                                                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z">
                                                </path>
                                            </svg></span></span></button>
                                            </div>
                                </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div class="col-lg-4 col-md-6 mb-4">
                                        <Link className="prod-link" to="">
                                            <div class="product-box">
                                                <div class="product-img">
                                                    <img src={BreakImg} alt="" />
                                                </div>
                                                <div class="product-content">
                                                    <div class="product-titles">
                                                        <h5>Brake drum for truck 1599012</h5>

                                                    </div>
                                                    <div class="product-tags">
                                                        <span>DT Spare Parts</span>
                                                        <span>Gear Box</span>
                                                        <span>Gear Box</span>
                                                    </div>
                                                    <div class="row price-cart">
                                                        <div class="col-6 cart-1">
                                                            <div class="product-cart text-center">
                                                                <Button><i class="bi bi-cart-plus"></i></Button>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 my-auto">
                                                            <div class="product-price   text-center">
                                                                <span>$200 <i class="bi bi-caret-right-fill"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ref-no">
                                                        Ref No: <span>BPO543002-UP(used)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ProductList