import React from 'react'
import attachBg from './../Images/truck-engine.jpg';
import { Link } from 'react-router-dom';

const ProductBanner = () => {
    return (
        <>





            <div className='product-banner' style={{ backgroundImage: `url(${attachBg})` }}>






                <div className='product-hero-overlay'></div>
                <div className='banner-content-1 z-2 absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 text-center'>
                    
                    <h1 className='text-5xl text-white mb-3'>Truck engine</h1>
                    <div className='text-white mb-4'>
                        <Link className='me-2 ms-2 inline-block' to="">Home</Link>
                        |
                        <Link className='me-2 ms-2 inline-block' to="">Stock</Link> |
                        <Link className='me-2 ms-2 inline-block' to="">Engine</Link> |
                        Complete engine
                    </div>
                    <p className='text-white max-w-2xl'>
                    Costs can build up quickly when your engine fails. Remanufacturing an engine is a time consuming process. You can choose for a used replacement engine at BAS Parts to get your truck running in the quickest and most cost-effective way possible. Engines get tested before they are put in stock at BAS Parts. Look below to find the right type with the desired mileage and specifications. Like, power-output, Euronorm and registration year.
                    </p>
                    
                </div>

            </div>
            <div className="bg-white pt-16 pb-10">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <h2 className="text-center text-3xl font-medium leading-8 text-gray-900">
                        Search for parts by brand
                    </h2>
                    <div className="mx-auto mt-14 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                        <img
                            alt="Transistor"
                            src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
                            width={158}
                            height={48}
                            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                        />
                        <img
                            alt="Reform"
                            src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
                            width={158}
                            height={48}
                            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                        />
                        <img
                            alt="Tuple"
                            src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
                            width={158}
                            height={48}
                            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                        />
                        <img
                            alt="SavvyCal"
                            src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
                            width={158}
                            height={48}
                            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                        />
                        <img
                            alt="Statamic"
                            src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
                            width={158}
                            height={48}
                            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductBanner